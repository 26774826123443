<template>
    <component
        :is="iconName"
        :class="iconClasses"
        @click="eventHandler"
    />
</template>

<script setup lang="ts">

const props = defineProps({
    icon: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: 'currentColor'
    },
    size: {
        type: String,
        default: 'base'
    },
    cursor: {
        type: Boolean,
        default: false
    },
    hidden: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: ''
    },
    filled: {
        type: Boolean,
        required: false,
        default: false
    }
});
const emit = defineEmits<{
  (e: 'action'): void
}>();
function eventHandler() {
    emit('action');
}
const iconName = computed(() => `svgo-${props.icon}`);
const sizeClasses : Record<string, string> = {
    xSmall: 'w-4 h-4',
    small: 'w-6 h-6',
    base: 'w-7 h-7',
    medium: 'w-8 h-8',
    large: 'w-10 h-10'
};
const colorClasses: Record<string, string> = {
    yellow: 'text-brand-500',
    blue: 'text-primary-700',
    white: 'text-white',
    neutral: 'text-neutral-500'
};
const sizeClass = computed(() => sizeClasses[props.size] || 'base');
const colorClass = computed(() => colorClasses[props.color] || 'currentColor');
const iconClasses = computed(() => {
    const classes = [
        colorClass.value,
        sizeClass.value,
        'nfs-icon'
    ];
    if (props.filled)
        classes.push('nfs-icon-fill');
    if (props.cursor)
        classes.push('cursor-pointer');

    return classes;
});
</script>

<style scoped>
.nfs-icon {
  @apply
  align-middle;
}
.nfs-icon-fill,
.nfs-icon-fill * {
  @apply
  fill-current;
}
</style>
